import ContainerPaper from '@/components/ContainerPaper';
import CopyTag from '@/components/copyTag';
import RangerDatePicker from '@/components/datePicker';
import { ReactECharts } from '@/components/echart';
import {
  useGetCompletedTestsRateDay,
  useGetCompletedTestsRateMonth,
  useGetCompletedTestsRateWeek,
} from '@/graphql/generated/query';
import time from '@flyer/utils/time';
import { Box } from '@mui/material';
import { Radio, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { EChartsOption } from 'echarts';
import { fill, groupBy, keys, mapValues, orderBy, round, sumBy, toNumber, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const renderLineParam = (
  p: { seriesName: string; marker: string; data: string; dataIndex: number }[],
) => {
  return p
    .map((el) => {
      const dataItem = toNumber(el?.data)?.toLocaleString('vi')?.toString() || '0';
      return `<div style="display: flex; justify-content: space-between; width: 300px">
        <div>${el.marker} ${el.seriesName} </div>
        <div style="font-weight: normal">${dataItem}</div>
        </div>`;
    })
    .join('');
};
const renderOption = (data: {
  [key: string]: {
    rate_premium: number;
    total_learner_profiles_premium: number;
    rate_free: number;
    total_completed_tests_free: number;
    total_learner_profiles_free: number;
    total_completed_tests: number;
    total_completed_tests_premium: number;
    total_learner_profiles: number;
    total_completed_tests_premium_exam: number;
    total_completed_tests_premium_mini: number;
    total_completed_tests_premium_practice: number;
    total_completed_tests_free_exam: number;
    total_completed_tests_free_mini: number;
    total_completed_tests_free_practice: number;
    total_completed_tests_free_placement_test: number;
    total_mobile_completed_tests: number;
    total_practice_mode_pre: number;
    total_practice_mode_free: number;
    total_completed_tests_ket_pet_speaking_free: number;
    total_completed_tests_ket_pet_speaking_pre: number;
    total_completed_tests_ket_pet_writing_free: number;
    total_completed_tests_ket_pet_writing_pre: number;
  };
  legend: { [key: string]: string };
}): EChartsOption => {
  const legend = { ...data.legend };
  delete data.legend;
  const listKeyA = keys(data)
    .reverse()
    .map((el) =>
      time(el, 'YYYY-MM-DD', true).isValid() ? time(el, 'YYYY-MM-DD').format('DD-MM-YYYY') : el,
    );

  const listKey = orderBy(
    listKeyA,
    (date) => {
      const dateTime = time(date, 'DD-MM-YYYY');
      if (dateTime.isValid()) {
        return dateTime;
      }
      if (date.includes('W')) {
        return toNumber(date.replace('W', ''));
      }
      return date;
    },
    'asc',
  );

  const listValue = values(data).reverse();
  const ratePremium = listValue.map((el) => el.rate_premium);

  const totalCompletedTestsExam = listValue.map((el) => el.total_completed_tests_premium_exam);
  const totalCompletedTestsQuiz = listValue.map((el) => el.total_completed_tests_premium_practice);
  const totalCompletedTestsMini = listValue.map((el) => el.total_completed_tests_premium_mini);

  const totalCompletedTestsFreeExam = listValue.map((el) => el.total_completed_tests_free_exam);
  const totalCompletedTestsFreeQuiz = listValue.map((el) => el.total_completed_tests_free_practice);
  const totalCompletedTestsFreePlacementTest = listValue.map(
    (el) => el.total_completed_tests_free_placement_test,
  );
  const totalCompletedTestsFreeMini = listValue.map((el) => el.total_completed_tests_free_mini);

  const totalLearnerProfilesPremium = listValue.map((el) => el.total_learner_profiles_premium);

  const rateFree = listValue.map((el) => el.rate_free);
  const totalCompletedTestsPremium = listValue.map((el) => el.total_completed_tests_premium);
  const totalCompletedTestsFree = listValue.map((el) => el.total_completed_tests_free);
  const totalLearnerProfilesFree = listValue.map((el) => el.total_learner_profiles_free);
  const totalCompletedTestMobile = listValue.map((el) => el.total_mobile_completed_tests);

  const totalPracticeModePre = listValue.map((el) => el.total_practice_mode_pre);
  const totalPracticeModeFree = listValue.map((el) => el.total_practice_mode_free);

  const totalLearnerProfiles = listValue.map((el) => el.total_learner_profiles);
  const totalCompletedTests = listValue.map((el) => el.total_completed_tests);
  const totalEmpty = fill([...listValue], 0);

  const totalCompletedTestsSpeakingKetPetPre = listValue.map(
    (el) => el.total_completed_tests_ket_pet_speaking_pre,
  );
  const totalCompletedTestsSpeakingKetPetFree = listValue.map(
    (el) => el.total_completed_tests_ket_pet_speaking_free,
  );
  const totalCompletedTestsWritingKetPetPre = listValue.map(
    (el) => el.total_completed_tests_ket_pet_writing_pre,
  );
  const totalCompletedTestsWritingKetPetFree = listValue.map(
    (el) => el.total_completed_tests_ket_pet_writing_free,
  );

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => {
        const newParams = params as unknown as [
          {
            seriesName: string;
            marker: string;
            data: string;
            dataIndex: number;
            axisValueLabel: string;
          },
        ];
        const timeDate = time(newParams?.[0]?.axisValueLabel, 'DD-MM-YYYY');
        let dateAxis = timeDate.isValid()
          ? timeDate.format('dddd - DD-MM-YYYY').toUpperCase()
          : newParams?.[0]?.axisValueLabel;

        if (dateAxis.length < 5 && dateAxis.includes('W')) {
          const weekNumber = toNumber(dateAxis.replace('W', ''));
          const startDate = time().week(weekNumber).startOf('week').format('DD/MM');
          const endDate = time().week(weekNumber).endOf('week').format('DD/MM');
          dateAxis = `${startDate} - ${endDate} - ${dateAxis}`;
        }

        const paramSectionTotal = newParams.filter((el) => el.seriesName.includes('Avg'));

        const paramSectionTrial = newParams.filter(
          (el) =>
            el.seriesName.includes('Exam Premium') ||
            el.seriesName.includes('Mini Premium') ||
            el.seriesName.includes('Quiz Premium') ||
            el.seriesName.includes('Practice Mode Pre') ||
            el.seriesName.includes('Ket Pet Speaking Pre') ||
            el.seriesName.includes('Ket Pet Writing Pre'),
        );

        const paramSectionFree = newParams.filter(
          (el) =>
            el.seriesName.includes('Exam Free') ||
            el.seriesName.includes('Mini Free') ||
            el.seriesName.includes('Placement Test Free') ||
            el.seriesName.includes('Quiz Free') ||
            el.seriesName.includes('Practice Mode Free') ||
            el.seriesName.includes('Ket Pet Speaking Free') ||
            el.seriesName.includes('Ket Pet Writing Free'),
        );

        const paramSectionMember = newParams.filter(
          (el) =>
            el.seriesName.includes('Profile Premium') || el.seriesName.includes('Profile Free'),
        );

        const totalCompletedParam = newParams.find((el) => el.seriesName === 'Total Test Premium');

        const totalCompletedTestPre =
          totalCompletedTestsPremium?.[totalCompletedParam?.dataIndex || 0] || '0';

        const totalCompletedTestFree =
          totalCompletedTestsFree?.[totalCompletedParam?.dataIndex || 0] || '0';
        const totalProfile = totalLearnerProfiles?.[totalCompletedParam?.dataIndex || 0] || '0';
        const totalCompleted = totalCompletedTests?.[totalCompletedParam?.dataIndex || 0] || '0';

        const totalMobile = totalCompletedTestMobile?.[totalCompletedParam?.dataIndex || 0] || '0';
        return `
            <div style="font-weight: bold; display: flex; justify-content: center; font-size: 16px">${dateAxis}</div>    
             <div style="font-weight: bold; display: flex; justify-content: center; font-size: 16px">
        Total Completed Test:  ${totalCompleted.toLocaleString('vi')}
             </div>
           ${
             paramSectionTotal?.length
               ? `<div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px">
           <span>Avg</span> 
           </div>
          <div>${renderLineParam(paramSectionTotal)}</div>
        <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>`
               : ''
           }
            <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Completed Test Premium</div>
            <div>${totalCompletedTestPre?.toLocaleString('vi')}</div>
            </div>
          <div>${renderLineParam(paramSectionTrial)}</div>
          
            <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>
            <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Completed Test Free</div>
            <div>${totalCompletedTestFree?.toLocaleString('vi')}</div>
            </div>
          <div>${renderLineParam(paramSectionFree)}</div>
          
             <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>
            <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Total Profile</div>
            <div>${totalProfile?.toLocaleString('vi')}</div>
             </div>
          <div>${renderLineParam(paramSectionMember)}</div>  
                    
                    <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>
            <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Total Mobile</div>
            <div>${totalMobile?.toLocaleString('vi')}</div>
             </div>
        `;
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true, title: 'Completed Test Rate', name: 'completed_tests_rate' },
        dataZoom: { show: true },
      },
    },
    legend: {
      type: 'scroll',
      bottom: 0,
      width: '80%',
      data: [
        // 'Total Completed Test Premium',
        // 'Total Completed Test Free',
        'Total Mobile',
        'Practice Mode Pre',
        'Practice Mode Free',
        'Full Exam Premium',
        'Mini Premium',
        'Quiz Premium',
        'Full Exam Free',
        'Mini Free',
        'Quiz Free',
        'Placement Test Free',
        'Total Profile',
        'Profile Premium',
        'Profile Free',
        'Avg Premium',
        'Avg Free',
        'Ket Pet Speaking Pre',
        'Ket Pet Writing Pre',
        'Ket Pet Speaking Free',
        'Ket Pet Writing Free',
      ],
      selected: {
        'Total Mobile': true,
        'Practice Mode Pre': true,
        'Practice Mode Free': true,
        'Avg Premium': false,
        'Avg Free': false,
        // 'Total Completed Test Premium': true,
        // 'Total Completed Test Free': true,
        'Total Profile': false,
        'Profile Premium': false,
        'Profile Free': false,
        'Full Exam Premium': true,
        'Mini Premium': true,
        'Quiz Premium': true,
        'Full Exam Free': true,
        'Mini Free': true,
        'Quiz Free': true,
        'Placement Test Free': true,
        'Ket Pet Speaking Pre': true,
        'Ket Pet Writing Pre': true,
        'Ket Pet Speaking Free': true,
        'Ket Pet Writing Free': true,
        ...legend,
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: listKey,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Avg Premium',
        position: 'right',
        alignTicks: true,
        // offset: 100,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Total Completed Test',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      // {
      //   type: 'value',
      //   name: 'Total Profile',
      //   position: 'right',
      //   alignTicks: true,
      //   offset: 180,
      //   axisLine: {
      //     show: true,
      //   },
      // },
    ],
    series: [
      {
        name: 'Avg Premium',
        type: 'line',
        data: ratePremium,
        yAxisIndex: 0,
        label: {
          show: true,
          position: 'top',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Avg Free',
        type: 'line',
        data: rateFree,
        yAxisIndex: 0,
        label: {
          show: true,
          position: 'top',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Full Exam Premium',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsExam,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Mini Premium',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsMini,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Quiz Premium',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsQuiz,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Practice Mode Pre',
        type: 'bar',
        yAxisIndex: 1,
        data: totalPracticeModePre,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Ket Pet Speaking Pre',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsSpeakingKetPetPre,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Ket Pet Writing Pre',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsWritingKetPetPre,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Full Exam Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsFreeExam,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Mini Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsFreeMini,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Quiz Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsFreeQuiz,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Placement Test Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsFreePlacementTest,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Practice Mode Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalPracticeModeFree,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Ket Pet Speaking Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsSpeakingKetPetFree,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Ket Pet Writing Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestsWritingKetPetFree,
        stack: 'b',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Test Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalEmpty,
        stack: 'b',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalCompletedTestsFree?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
      {
        name: 'Total Test Premium',
        type: 'bar',
        yAxisIndex: 1,
        data: totalEmpty,
        stack: 'a',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalCompletedTestsPremium?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
      {
        name: 'Profile Premium',
        type: 'bar',
        yAxisIndex: 1,
        data: totalLearnerProfilesPremium,
        stack: 'c',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Profile Free',
        type: 'bar',
        yAxisIndex: 1,
        data: totalLearnerProfilesFree,
        stack: 'c',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Profile',
        type: 'bar',
        yAxisIndex: 1,
        data: totalEmpty,
        stack: 'c',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalLearnerProfiles?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
      {
        name: 'Total Mobile',
        type: 'bar',
        yAxisIndex: 1,
        data: totalCompletedTestMobile,
        stack: 'mobile',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Mobile Full',
        type: 'bar',
        yAxisIndex: 1,
        data: totalEmpty,
        stack: 'mobile',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalCompletedTestMobile?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
    ],
  };
};

enum CompletedTestView {
  DAY,
  WEEK,
  MONTH,
}
function CompletedTest() {
  // const { themeMode } = useSettings();
  const [date, setDate] = useState<RangeValue>([time().subtract(2, 'week'), time()]);

  const [typeView, setTypeView] = useState(CompletedTestView.DAY);

  const [legend, setLegend] = useState({
    'Total Mobile': true,
    'Practice Mode Pre': true,
    'Practice Mode Free': true,
    'Avg Premium': false,
    'Avg Free': false,
    'Total Profile': false,
    'Profile Premium': false,
    'Profile Free': false,
    'Full Exam Premium': true,
    'Mini Premium': true,
    'Quiz Premium': true,
    'Full Exam Free': true,
    'Mini Free': true,
    'Quiz Free': true,
    'Placement Test Free': true,
  });

  const { data: dataByDay, loading: loadingTestDay } = useGetCompletedTestsRateDay({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.DAY,
  });

  const { data: dataByWeek, loading: loadingTestWeek } = useGetCompletedTestsRateWeek({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.WEEK,
  });

  const { data: dataByMonth, loading: loadingTestMonth } = useGetCompletedTestsRateMonth({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.MONTH,
  });

  const transferDataView = useMemo(() => {
    let groupData = groupBy(
      dataByWeek?.completed_tests_rate_by_week,
      (item) => `W${time(item.date).week()}-${time(item.date).year()}`,
    );

    if (typeView === CompletedTestView.DAY) {
      groupData = groupBy(dataByDay?.completed_tests_rate_by_day, (item) =>
        time(item.date).format('YYYY-MM-DD'),
      );
    }

    if (typeView === CompletedTestView.MONTH) {
      groupData = groupBy(dataByMonth?.completed_tests_rate_by_month, (item) =>
        time(item.date).format('YYYY-MM'),
      );
    }

    const values = mapValues(groupData, (item) => ({
      rate_premium: round(sumBy(item, 'rate_premium'), 2),
      total_completed_tests_premium: sumBy(item, 'total_completed_tests_premium'),
      total_learner_profiles_premium: sumBy(item, 'total_learner_profiles_premium'),
      rate_free: round(sumBy(item, 'rate_free'), 2),
      total_completed_tests_free: sumBy(item, 'total_completed_tests_free'),
      total_learner_profiles_free: sumBy(item, 'total_learner_profiles_free'),
      total_learner_profiles: sumBy(item, 'total_learner_profiles'),
      total_completed_tests: sumBy(item, 'total_completed_tests'),
      total_completed_tests_premium_exam: sumBy(item, 'total_completed_tests_premium_exam'),
      total_completed_tests_premium_mini: sumBy(item, 'total_completed_tests_premium_mini'),
      total_completed_tests_premium_practice: sumBy(item, 'total_completed_tests_premium_practice'),
      total_completed_tests_free_exam: sumBy(item, 'total_completed_tests_free_exam'),
      total_completed_tests_free_mini: sumBy(item, 'total_completed_tests_free_mini'),
      total_completed_tests_free_practice: sumBy(item, 'total_completed_tests_free_practice'),
      total_completed_tests_free_placement_test: sumBy(
        item,
        'total_completed_tests_free_placement_test',
      ),
      total_mobile_completed_tests: sumBy(item, 'total_mobile_completed_tests'),

      total_practice_mode_pre: sumBy(item, 'total_practice_mode_pre'),
      total_practice_mode_free: sumBy(item, 'total_practice_mode_free'),
      total_completed_tests_ket_pet_speaking_pre: sumBy(
        item,
        'total_completed_tests_ket_pet_speaking_pre',
      ),
      total_completed_tests_ket_pet_speaking_free: sumBy(
        item,
        'total_completed_tests_ket_pet_speaking_free',
      ),
      total_completed_tests_ket_pet_writing_pre: sumBy(
        item,
        'total_completed_tests_ket_pet_writing_pre',
      ),
      total_completed_tests_ket_pet_writing_free: sumBy(
        item,
        'total_completed_tests_ket_pet_writing_free',
      ),
    }));
    values.legend = legend;
    return values;
  }, [
    dataByDay?.completed_tests_rate_by_day,
    dataByMonth?.completed_tests_rate_by_month,
    dataByWeek?.completed_tests_rate_by_week,
    typeView,
    legend,
  ]);

  const dataTest = useMemo(() => {
    let data = dataByWeek?.completed_tests_rate_by_week;
    if (typeView === CompletedTestView.DAY) {
      data = dataByDay?.completed_tests_rate_by_day;
    }

    if (typeView === CompletedTestView.MONTH) {
      data = dataByMonth?.completed_tests_rate_by_month;
    }
    return data;
  }, [
    dataByDay?.completed_tests_rate_by_day,
    dataByMonth?.completed_tests_rate_by_month,
    dataByWeek?.completed_tests_rate_by_week,
    typeView,
  ]);

  const totalCompletedTestPremiumExam = useMemo(
    () =>
      legend['Full Exam Premium'] ? sumBy(dataTest, 'total_completed_tests_premium_exam') || 0 : 0,
    [dataTest, legend],
  );

  const totalCompletedTestPremiumMini = useMemo(
    () => (legend['Mini Premium'] ? sumBy(dataTest, 'total_completed_tests_premium_mini') || 0 : 0),
    [dataTest, legend],
  );
  const totalCompletedTesPremiumQuiz = useMemo(
    () =>
      legend['Quiz Premium'] ? sumBy(dataTest, 'total_completed_tests_premium_practice') || 0 : 0,
    [dataTest, legend],
  );

  const totalCompletedTestFreeExam = useMemo(
    () => (legend['Full Exam Free'] ? sumBy(dataTest, 'total_completed_tests_free_exam') || 0 : 0),
    [dataTest, legend],
  );
  const totalCompletedTestFreeMini = useMemo(
    () => (legend['Mini Free'] ? sumBy(dataTest, 'total_completed_tests_free_mini') || 0 : 0),
    [dataTest, legend],
  );
  const totalCompletedTesFreeQuiz = useMemo(
    () => (legend['Quiz Free'] ? sumBy(dataTest, 'total_completed_tests_free_practice') || 0 : 0),
    [dataTest, legend],
  );

  const totalCompletedTesPlacementTest = useMemo(
    () =>
      legend['Placement Test Free']
        ? sumBy(dataTest, 'total_completed_tests_free_placement_test') || 0
        : 0,
    [dataTest, legend],
  );

  const totalCompletedTestMobile = useMemo(
    () => sumBy(dataTest, 'total_mobile_completed_tests') || 0,
    [dataTest],
  );

  const totalLearnerProfile = useMemo(
    () => sumBy(dataTest, 'total_learner_profiles') || 0,
    [dataTest],
  );

  const totalPracticeModePre = useMemo(
    () => sumBy(dataTest, 'total_practice_mode_pre') || 0,
    [dataTest],
  );
  const totalPracticeModeFree = useMemo(
    () => sumBy(dataTest, 'total_practice_mode_free') || 0,
    [dataTest],
  );

  const totalCompletedTest = useMemo(() => {
    let dataTotalCom = sumBy(dataTest, 'total_completed_tests') || 0;

    if (!legend['Quiz Free']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_free_practice') || 0;
    }

    if (!legend['Mini Free']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_free_mini') || 0;
    }

    if (!legend['Full Exam Free']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_free_exam') || 0;
    }

    if (!legend['Quiz Premium']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_premium_practice') || 0;
    }

    if (!legend['Full Exam Premium']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_premium_exam') || 0;
    }

    if (!legend['Total Mobile']) {
      dataTotalCom -= sumBy(dataTest, 'total_mobile_completed_tests') || 0;
    }

    if (!legend['Practice Premium']) {
      dataTotalCom -= sumBy(dataTest, 'total_practice_mode_pre') || 0;
    }

    if (!legend['Practice Free']) {
      dataTotalCom -= sumBy(dataTest, 'total_practice_mode_free') || 0;
    }

    return dataTotalCom;
  }, [dataTest, legend]);

  const option = useMemo(() => renderOption(transferDataView), [transferDataView]);

  const onChangeLegend = useCallback((params: { [key: string]: boolean }) => {
    setLegend(params);
  }, []);

  return (
    <ContainerPaper>
      <Typography.Title level={2}>Completed Test</Typography.Title>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <CopyTag valueCopy={totalCompletedTest.toString()} color="success">
          Total Completed Test: {totalCompletedTest.toLocaleString('vi')}
        </CopyTag>

        <CopyTag valueCopy={totalCompletedTest.toString()} color="success">
          Total Practice Mode Pre: {totalPracticeModePre.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTest.toString()} color="success">
          Total Practice Mode Free: {totalPracticeModeFree.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalLearnerProfile.toString()} color="success">
          Total Learner Profile: {totalLearnerProfile.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTestPremiumExam.toString()}>
          Total Exam Premium: {totalCompletedTestPremiumExam.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTestPremiumMini.toString()}>
          Total Mini Premium: {totalCompletedTestPremiumMini.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTesPremiumQuiz.toString()}>
          Total Quiz Premium: {totalCompletedTesPremiumQuiz.toLocaleString('vi')}
        </CopyTag>

        <CopyTag valueCopy={totalCompletedTestPremiumExam.toString()}>
          Total Exam Free: {totalCompletedTestFreeExam.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTestFreeMini.toString()}>
          Total Mini Free: {totalCompletedTestFreeMini.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalCompletedTesFreeQuiz.toString()}>
          Total Quiz Free: {totalCompletedTesFreeQuiz.toLocaleString('vi')}
        </CopyTag>

        <CopyTag valueCopy={totalCompletedTesPlacementTest.toString()}>
          Total Placement Test: {totalCompletedTesPlacementTest.toLocaleString('vi')}
        </CopyTag>
      </Box>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RangerDatePicker onChange={setDate} value={date} />
        <Radio.Group
          value={typeView}
          onChange={(e) => {
            setTypeView(e.target.value as number);
          }}
        >
          <Radio.Button value={CompletedTestView.DAY}>Day</Radio.Button>
          <Radio.Button value={CompletedTestView.WEEK}>Week</Radio.Button>
          <Radio.Button value={CompletedTestView.MONTH}>Month</Radio.Button>
        </Radio.Group>
      </Box>
      <ReactECharts
        option={option}
        style={{ width: '100%', height: '500px' }}
        // theme={themeMode}
        loading={loadingTestDay || loadingTestWeek || loadingTestMonth}
        onChangeLegend={onChangeLegend}
      />
    </ContainerPaper>
  );
}
export default CompletedTest;
