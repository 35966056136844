import ContainerPaper from '@/components/ContainerPaper';
import CopyTag from '@/components/copyTag';
import RangerDatePicker from '@/components/datePicker';
import { ReactECharts } from '@/components/echart';
import {
  useGetUnCompletedTestsRateByDay,
  useGetUnCompletedTestsRateByMonth,
  useGetUnCompletedTestsRateByWeek,
} from '@/graphql/generated/query';
import time from '@flyer/utils/time';
import { Box } from '@mui/material';
import { Radio, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { EChartsOption } from 'echarts';
import { fill, groupBy, keys, mapValues, orderBy, sumBy, toNumber, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const renderLineParam = (
  p: { seriesName: string; marker: string; data: string; dataIndex: number }[],
) => {
  return p
    .map((el) => {
      const dataItem = toNumber(el?.data)?.toLocaleString('vi')?.toString() || '0';
      return `<div style="display: flex; justify-content: space-between; width: 300px">
        <div>${el.marker} ${el.seriesName} </div>
        <div style="font-weight: normal">${dataItem}</div>
        </div>`;
    })
    .join('');
};
const renderOption = (data: {
  [key: string]: {
    total_un_completed_tests: number;
    total_un_completed_tests_exam: number;
    total_mobile_un_completed_tests: number;
    total_practice_mode: number;
    total_un_completed_tests_mini: number;
  };
  legend: { [key: string]: string };
}): EChartsOption => {
  const legend = { ...data.legend };
  delete data.legend;
  const listKeyA = keys(data)
    .reverse()
    .map((el) =>
      time(el, 'YYYY-MM-DD', true).isValid() ? time(el, 'YYYY-MM-DD').format('DD-MM-YYYY') : el,
    );

  const listKey = orderBy(
    listKeyA,
    (date) => {
      const dateTime = time(date, 'DD-MM-YYYY');
      if (dateTime.isValid()) {
        return dateTime;
      }
      if (date.includes('W')) {
        return toNumber(date.replace('W', ''));
      }
      return date;
    },
    'asc',
  );

  const listValue = values(data).reverse();

  const totalUnCompletedTestsExam = listValue.map((el) => el.total_un_completed_tests_exam);

  const totalUnCompletedTestsMini = listValue.map((el) => el.total_un_completed_tests_mini);

  const totalUnCompletedTestMobile = listValue.map((el) => el.total_mobile_un_completed_tests);

  const totalPracticeMode = listValue.map((el) => el.total_practice_mode);

  const totalUnCompletedTests = listValue.map((el) => el.total_un_completed_tests);

  const totalEmpty = fill([...listValue], 0);

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => {
        const newParams = params as unknown as [
          {
            seriesName: string;
            marker: string;
            data: string;
            dataIndex: number;
            axisValueLabel: string;
          },
        ];
        const timeDate = time(newParams?.[0]?.axisValueLabel, 'DD-MM-YYYY');
        let dateAxis = timeDate.isValid()
          ? timeDate.format('dddd - DD-MM-YYYY').toUpperCase()
          : newParams?.[0]?.axisValueLabel;

        if (dateAxis.length < 5 && dateAxis.includes('W')) {
          const weekNumber = toNumber(dateAxis.replace('W', ''));
          const startDate = time().week(weekNumber).startOf('week').format('DD/MM');
          const endDate = time().week(weekNumber).endOf('week').format('DD/MM');
          dateAxis = `${startDate} - ${endDate} - ${dateAxis}`;
        }

        const paramSectionTotal = newParams.find((el) => el.seriesName === 'Total');

        const paramSectionOther = newParams.filter((el) =>
          ['Total Mobile', 'Full Exam', 'Practice Mode', 'Mini'].includes(el.seriesName),
        );

        const total = totalUnCompletedTests?.[paramSectionTotal?.dataIndex || 0] || '0';

        return `
            <div style="font-weight: bold; display: flex; justify-content: center; font-size: 16px">${dateAxis}</div>    
             <div style="font-weight: bold; display: flex; justify-content: center; font-size: 16px">
        Total Un Completed Test:  ${total.toLocaleString('vi')}
             </div>
                    <div>${renderLineParam(paramSectionOther)}</div>
           
        `;
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true, title: 'Completed Test Rate', name: 'completed_tests_rate' },
        dataZoom: { show: true },
      },
    },
    legend: {
      type: 'scroll',
      bottom: 0,
      width: '80%',
      data: ['Total', 'Total Mobile', 'Full Exam', 'Practice Mode', 'Mini'],
      selected: {
        Total: true,
        'Total Mobile': true,
        'Full Exam': true,
        'Practice Mode': true,
        Mini: true,
        ...legend,
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: listKey,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Total Un Completed Test',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: 'Mini',
        type: 'bar',
        yAxisIndex: 0,
        data: totalUnCompletedTestsMini,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Practice Mode',
        type: 'bar',
        yAxisIndex: 0,
        data: totalPracticeMode,
        stack: 'a',
        color: '#eb9636',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Full Exam',
        type: 'bar',
        yAxisIndex: 0,
        data: totalUnCompletedTestsExam,
        stack: 'a',
        color: '#70c754',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Mobile',
        type: 'bar',
        yAxisIndex: 0,
        data: totalUnCompletedTestMobile,
        stack: 'a',
        color: '#ebe428',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total',
        type: 'bar',
        yAxisIndex: 0,
        data: totalEmpty,
        stack: 'a',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalUnCompletedTests?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
    ],
  };
};

enum CompletedTestView {
  DAY,
  WEEK,
  MONTH,
}
function UnCompletedTest() {
  // const { themeMode } = useSettings();
  const [date, setDate] = useState<RangeValue>([time().subtract(2, 'week'), time()]);

  const [typeView, setTypeView] = useState(CompletedTestView.DAY);

  const [legend, setLegend] = useState({
    'Total Mobile': true,
    'Practice Mode': true,
    'Full Exam': true,
    Mini: true,
  });

  const { data: dataByDay, loading: loadingTestDay } = useGetUnCompletedTestsRateByDay({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.DAY,
  });

  const { data: dataByWeek, loading: loadingTestWeek } = useGetUnCompletedTestsRateByWeek({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.WEEK,
  });

  const { data: dataByMonth, loading: loadingTestMonth } = useGetUnCompletedTestsRateByMonth({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.MONTH,
  });

  const transferDataView = useMemo(() => {
    let groupData = groupBy(
      dataByWeek?.un_completed_tests_rate_by_week,
      (item) => `W${time(item.date).week()}-${time(item.date).year()}`,
    );

    if (typeView === CompletedTestView.DAY) {
      groupData = groupBy(dataByDay?.un_completed_tests_rate_by_day, (item) =>
        time(item.date).format('YYYY-MM-DD'),
      );
    }

    if (typeView === CompletedTestView.MONTH) {
      groupData = groupBy(dataByMonth?.un_completed_tests_rate_by_month, (item) =>
        time(item.date).format('YYYY-MM'),
      );
    }

    const values = mapValues(groupData, (item) => ({
      total_un_completed_tests: sumBy(item, 'total_un_completed_tests'),
      total_un_completed_tests_exam: sumBy(item, 'total_un_completed_tests_exam'),
      total_mobile_un_completed_tests: sumBy(item, 'total_mobile_un_completed_tests'),
      total_practice_mode: sumBy(item, 'total_practice_mode'),
      total_un_completed_tests_mini: sumBy(item, 'total_un_completed_tests_mini'),
    }));
    values.legend = legend;
    return values;
  }, [
    dataByDay?.un_completed_tests_rate_by_day,
    dataByMonth?.un_completed_tests_rate_by_month,
    dataByWeek?.un_completed_tests_rate_by_week,
    typeView,
    legend,
  ]);

  const dataTest = useMemo(() => {
    let data = dataByWeek?.un_completed_tests_rate_by_week;
    if (typeView === CompletedTestView.DAY) {
      data = dataByDay?.un_completed_tests_rate_by_day;
    }

    if (typeView === CompletedTestView.MONTH) {
      data = dataByMonth?.un_completed_tests_rate_by_month;
    }
    return data;
  }, [
    dataByDay?.un_completed_tests_rate_by_day,
    dataByMonth?.un_completed_tests_rate_by_month,
    dataByWeek?.un_completed_tests_rate_by_week,
    typeView,
  ]);

  const totalUnCompletedTestExam = useMemo(
    () => (legend['Full Exam'] ? sumBy(dataTest, 'total_un_completed_tests_exam') || 0 : 0),
    [dataTest, legend],
  );

  const totalUnCompletedTestMini = useMemo(
    () => (legend['Mini'] ? sumBy(dataTest, 'total_un_completed_tests_mini') || 0 : 0),
    [dataTest, legend],
  );

  const totalUnCompletedTestMobile = useMemo(
    () => sumBy(dataTest, 'total_mobile_un_completed_tests') || 0,
    [dataTest],
  );

  const totalPracticeMode = useMemo(() => sumBy(dataTest, 'total_practice_mode') || 0, [dataTest]);

  const totalUnCompletedTest = useMemo(() => {
    let dataTotalCom = sumBy(dataTest, 'total_un_completed_tests') || 0;

    if (!legend['Mini']) {
      dataTotalCom -= sumBy(dataTest, 'total_completed_tests_free_mini') || 0;
    }

    if (!legend['Full Exam']) {
      dataTotalCom -= sumBy(dataTest, 'total_un_completed_tests_exam') || 0;
    }

    if (!legend['Total Mobile']) {
      dataTotalCom -= sumBy(dataTest, 'total_mobile_un_completed_tests') || 0;
    }

    if (!legend['Practice Mode']) {
      dataTotalCom -= sumBy(dataTest, 'total_practice_mode') || 0;
    }

    return dataTotalCom;
  }, [dataTest, legend]);

  const option = useMemo(() => renderOption(transferDataView), [transferDataView]);

  const onChangeLegend = useCallback((params: { [key: string]: boolean }) => {
    setLegend(params);
  }, []);

  return (
    <ContainerPaper>
      <Typography.Title level={2}>Un Completed Test</Typography.Title>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <CopyTag valueCopy={totalUnCompletedTest.toString()} color="success">
          Total Un Completed Test: {totalUnCompletedTest.toLocaleString('vi')}
        </CopyTag>

        <CopyTag valueCopy={totalPracticeMode.toString()}>
          Total Practice Mode: {totalPracticeMode.toLocaleString('vi')}
        </CopyTag>

        <CopyTag valueCopy={totalUnCompletedTestExam.toString()}>
          Total Exam: {totalUnCompletedTestExam.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalUnCompletedTestMini.toString()}>
          Total Mini Test: {totalUnCompletedTestMini.toLocaleString('vi')}
        </CopyTag>
      </Box>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RangerDatePicker onChange={setDate} value={date} />
        <Radio.Group
          value={typeView}
          onChange={(e) => {
            setTypeView(e.target.value as number);
          }}
        >
          <Radio.Button value={CompletedTestView.DAY}>Day</Radio.Button>
          <Radio.Button value={CompletedTestView.WEEK}>Week</Radio.Button>
          <Radio.Button value={CompletedTestView.MONTH}>Month</Radio.Button>
        </Radio.Group>
      </Box>
      <ReactECharts
        option={option}
        style={{ width: '100%', height: '500px' }}
        loading={loadingTestDay || loadingTestWeek || loadingTestMonth}
        onChangeLegend={onChangeLegend}
      />
    </ContainerPaper>
  );
}
export default UnCompletedTest;
