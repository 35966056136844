import { useCopyToClipboard } from '@flyer/utils/hooks';
import { Tag, Tooltip } from 'antd';
import { ReactNode, useState } from 'react';

type CopyTagProp = {
  valueCopy: string;
  color?: string;
  children: ReactNode;
};
function CopyTag(props: CopyTagProp) {
  const { valueCopy, color, children } = props;
  const [open, setOpen] = useState(false);

  const onCopy = useCopyToClipboard();
  return (
    <Tooltip title={`Copied ${valueCopy}`} color="green" open={open} destroyTooltipOnHide>
      <Tag
        style={{ cursor: 'pointer' }}
        color={color}
        onClick={() => {
          setOpen(true);
          onCopy(valueCopy);
          setTimeout(() => {
            setOpen(false);
          }, 1000);
        }}
      >
        {children}
      </Tag>
    </Tooltip>
  );
}

export default CopyTag;
