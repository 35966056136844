import { useEffect } from 'react';

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = 'Are you sure you want to exit?';
};

export default function useBeforeUnload(options: { isDirty: boolean; disabled: boolean }) {
  const { isDirty, disabled } = options;

  useEffect(() => {
    if (disabled) {
      return () => {};
    }

    if (isDirty) {
      window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
    } else {
      window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
    }

    return () =>
      window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }, [disabled, isDirty]);
}
