import ContainerPaper from '@/components/ContainerPaper';
import CopyTag from '@/components/copyTag';
import RangerDatePicker from '@/components/datePicker';
import { ReactECharts } from '@/components/echart';
import { QUERY } from '@/constants';
import { useGetMembership } from '@/graphql/generated/query';
import time from '@flyer/utils/time';
import { Box } from '@mui/material';
import { Radio, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { ECElementEvent, EChartsOption } from 'echarts';
import {
  fill,
  groupBy,
  keys,
  mapValues,
  merge,
  orderBy,
  round,
  sumBy,
  toNumber,
  values,
} from 'lodash-es';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const renderLineParam = (
  p: { seriesName: string; marker: string; data: string; dataIndex: number }[],
  totalTrial: number[],
  ratioRetailAgent?: number[],
) => {
  return p
    .map((el) => {
      let dataItem = toNumber(el?.data)?.toLocaleString('vi')?.toString() || '0';
      if (el.seriesName === 'Trial') {
        dataItem = toNumber(totalTrial?.[el.dataIndex])?.toLocaleString('vi')?.toString() || '0';
      }
      if (ratioRetailAgent && el.seriesName === 'Ratio Retail/Agent') {
        dataItem = `${ratioRetailAgent?.[el.dataIndex]?.toString() || '0'}%`;
      } else if (el.seriesName === 'Trial To Membership') {
        dataItem = `${dataItem}%`;
      }
      return `<div style="display: flex; justify-content: space-between ">
        <div>${el.marker} ${el.seriesName} </div>
        <div>${dataItem}</div>
        </div>`;
    })
    .join('');
};
const renderOption = (data: {
  [key: string]: {
    trial: number;
    retail: number;
    retailManual: number;
    agent: number;
    partner: number;
    exam: number;
    mobile: number;
    custom: number;
    sace: number;
    showSace?: boolean;
  };
}): EChartsOption => {
  const { show: showProps } = data;
  const { showSace } = showProps || {};
  delete data.show;

  const listKeyA = keys(data).map((el) =>
    time(el, 'YYYY-MM-DD', true).isValid() ? time(el, 'YYYY-MM-DD').format('DD-MM-YYYY') : el,
  );

  const listKey = orderBy(
    listKeyA,
    (date) => {
      const dateTime = time(date, 'DD-MM-YYYY');
      if (dateTime.isValid()) {
        return dateTime;
      }
      if (date.includes('W')) {
        return toNumber(date.replace('W', ''));
      }
      return date;
    },
    'asc',
  );

  const listValue = listKey.map((el) => {
    const key = time(el, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const dataFind = data[key] || data[el];
    return dataFind || {};
  });

  const exam = listValue.map((el) => el.exam || 0);
  const mobile = listValue.map((el) => el.mobile || 0);
  const custom = listValue.map((el) => el.custom || 0);
  const retail = listValue.map((el) => el.retail || 0);
  const retailManual = listValue.map((el) => el.retailManual || 0);
  const partner = listValue.map((el) => el.partner || 0);
  const agent = listValue.map((el) => el.agent || 0);
  const sace = listValue.map((el) => el.sace || 0);
  const membership = retail.map(
    (el, i) => el + (agent?.[i] || 0) + (retailManual?.[i] || 0) + (partner?.[i] || 0),
  );
  const totalTrial = listValue.map((el) => el.trial || 0);
  const totalTrialWithoutSace = listValue.map((el, i) => (el.trial || 0) - (sace[i] || 0));
  const trial = fill([...listValue], 0);

  const rateTrialMembership = membership.map((el, i) =>
    totalTrial[i] ? Math.floor((el / (totalTrial?.[i] || 1)) * 100) : 0,
  );

  const ratioRetailAgent = retail.map((el, i) =>
    totalTrial[i] ? Math.floor((el / (agent?.[i] || 1)) * 100) : 0,
  );
  const ratioRetailAgentFake = fill(
    retail.map((el, i) => (totalTrial[i] ? Math.floor((el / (agent?.[i] || 1)) * 100) : 0)),
    0,
  );

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => {
        const newParams = params as unknown as [
          {
            seriesName: string;
            marker: string;
            data: string;
            dataIndex: number;
            axisValueLabel: string;
          },
        ];
        const timeDate = time(newParams?.[0]?.axisValueLabel, 'DD-MM-YYYY');
        let dateAxis = timeDate.isValid()
          ? timeDate.format('dddd - DD-MM-YYYY').toUpperCase()
          : newParams?.[0]?.axisValueLabel;

        if (dateAxis.length < 5 && dateAxis.includes('W')) {
          const weekNumber = toNumber(dateAxis.replace('W', ''));
          const startDate = time().week(weekNumber).startOf('week').format('DD/MM');
          const endDate = time().week(weekNumber).endOf('week').format('DD/MM');
          dateAxis = `${startDate} - ${endDate} - ${dateAxis}`;
        }

        const dataIndex = newParams?.[0]?.dataIndex;

        const paramSectionTrial = newParams.filter((el) =>
          ['Exam', 'Mobile', 'Other', 'Sace'].includes(el.seriesName),
        );
        const paramSectionMember = newParams.filter((el) =>
          ['Retail', 'RetailManual', 'Agent', 'Partner'].includes(el.seriesName),
        );

        const paramSectionRatio = newParams.filter((el) =>
          ['Ratio Retail/Agent', 'Trial To Membership'].includes(el.seriesName),
        );

        const dataTotalTrial =
          (showSace ? totalTrial[dataIndex] : totalTrialWithoutSace[dataIndex]) || 0;

        return `
           <div style="font-weight: bold; display: flex; justify-content: center">
           <div style="font-weight: bold; font-size: 16px;">${dateAxis}</div>
           </div> 
          
            <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div style="min-width: 200px">Total Trial</div>
             <div>${toNumber(dataTotalTrial).toLocaleString('vi')}</div>
             </div>
            <div>${renderLineParam(paramSectionTrial, totalTrial)}</div>
                <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>
                   <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Total Membership</div>
             <div>${toNumber(membership?.[dataIndex] || 0).toLocaleString('vi')}</div>
             </div>
          <div>${renderLineParam(paramSectionMember, totalTrial)}</div>
          </div>
          
          ${
            paramSectionRatio.length > 0
              ? `
          <hr style="border: 1px dashed #ccc; margin: 8px 0 8px 0"/>
            <div style="font-weight: bold;  font-size: 16px;">Ratio</div> 
          <div>${renderLineParam(paramSectionRatio, totalTrial, ratioRetailAgent)}</div>
          `
              : ''
          }
        `;
      },
    },
    grid: {
      right: '15%',
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true, title: 'Membership', name: `membership-${time().format()}` },
        dataZoom: { show: true },
      },
    },
    legend: {
      data: [
        'Exam',
        'Mobile',
        'Sace',
        'Other',
        'Retail',
        'RetailManual',
        'Partner',
        'Agent',
        'Total Active Code',
        'Trial To Membership',
        'Ratio Retail/Agent',
      ],
      selected: {
        Exam: true,
        Mobile: true,
        Sace: showSace || false,
        Other: false,
        Retail: true,
        RetailManual: true,
        Partner: true,
        Agent: true,
        'Total Active Code': true,
        'Trial To Membership': false,
        'Ratio Retail/Agent': false,
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: listKey,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Trial',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Membership',
        position: 'right',
        alignTicks: true,
        offset: 60,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Membership/Trial',
        position: 'right',
        alignTicks: true,
        offset: 160,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: 'Exam',
        type: 'bar',
        data: exam,
        stack: 'trial',
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Mobile',
        type: 'bar',
        data: mobile,
        stack: 'trial',
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Other',
        type: 'bar',
        data: custom,
        stack: 'trial',
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            if (params.data) {
              return params.data.toString();
            }
            return '';
          },
        },
      },
      {
        name: 'Sace',
        type: 'bar',
        data: sace,
        stack: 'trial',
        label: {
          show: true,
          position: 'inside',
          formatter: (params) => {
            if (params.data) {
              return params.data.toString();
            }
            return '';
          },
        },
      },
      {
        name: 'Trial',
        type: 'bar',
        data: trial,
        stack: 'trial',
        label: {
          show: true,
          position: 'top',
          formatter: (params) => {
            return showSace
              ? totalTrial?.[params?.dataIndex]?.toLocaleString('vi')?.toString() || ''
              : totalTrialWithoutSace?.[params?.dataIndex]?.toLocaleString('vi')?.toString() || '';
          },
        },
      },
      {
        name: 'Retail',
        type: 'bar',
        yAxisIndex: 0,
        data: retail,
        stack: 'Ad',
        label: {
          show: true,
          position: 'inside',
        },
      },
      {
        name: 'RetailManual',
        type: 'bar',
        yAxisIndex: 0,
        data: retailManual,
        stack: 'Ad',
        label: {
          show: true,
          position: 'inside',
        },
      },
      {
        name: 'Agent',
        type: 'bar',
        yAxisIndex: 0,
        data: agent,
        stack: 'Ad',
        label: {
          show: true,
          position: 'inside',
        },
      },
      {
        name: 'Partner',
        type: 'bar',
        yAxisIndex: 0,
        data: partner,
        stack: 'Ad',
        label: {
          show: true,
          position: 'inside',
        },
      },
      {
        name: 'Total Active Code',
        type: 'bar',
        yAxisIndex: 0,
        data: trial,
        stack: 'Ad',
        label: {
          show: true,
          position: 'top',
          formatter: (params) => membership?.[params.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
      {
        name: 'Trial To Membership',
        type: 'line',
        yAxisIndex: 2,
        data: rateTrialMembership,
        label: {
          show: true,
          position: 'top',
          formatter: '{c}%',
        },
      },
      {
        name: 'Ratio Retail/Agent',
        type: 'bar',
        yAxisIndex: 0,
        stack: 'Ad',
        data: ratioRetailAgentFake,
        label: {
          show: true,
          position: 'top',
          formatter: (params) => `${ratioRetailAgent?.[params?.dataIndex]?.toString() || ''}%`,
        },
      },
    ],
  };
};

enum CompletedTestView {
  MONTH,
  WEEK,
  DAY,
}
function Membership() {
  const [date, setDate] = useState<RangeValue>([time().subtract(2, 'week'), time()]);
  const [showSace, setShowSace] = useState(false);
  const router = useRouter();

  const [typeView, setTypeView] = useState(CompletedTestView.DAY);

  const { data, loading: loadingTest } = useGetMembership({
    variables: {
      date: {
        _gte: date?.[0]?.startOf('d')?.toISOString(),
        _lt: date?.[1]?.endOf('d')?.toISOString(),
      },
    },
    skip: !date,
  });

  const transferDataView = useMemo(() => {
    let groupData = groupBy(data?.membership, (item) => time(item.date).format('YYYY-MM'));
    let groupDataTrial = groupBy(data?.trial, (item) => time(item.date).format('YYYY-MM'));

    if (typeView === CompletedTestView.DAY) {
      groupData = groupBy(data?.membership, (item) => time(item.date).format('YYYY-MM-DD'));

      groupDataTrial = groupBy(data?.trial, (item) => time(item.date).format('YYYY-MM-DD'));
    }

    if (typeView === CompletedTestView.WEEK) {
      groupData = groupBy(data?.membership, (item) => `W${time(item.date).week()}`);

      groupDataTrial = groupBy(data?.trial, (item) => `W${time(item.date).week()}`);
    }

    const membership = mapValues(groupData, (item) => ({
      retail: round(sumBy(item, 'codeTypeCount.retail')) || 0,
      retailManual: round(sumBy(item, 'codeTypeCount.retail_manual')) || 0,
      agent: round(sumBy(item, 'codeTypeCount.agent')) || 0,
      partner: round(sumBy(item, 'codeTypeCount.partner')) || 0,
    }));

    const trial = mapValues(groupDataTrial, (item) => ({
      trial: round(sumBy(item, 'count')) || 0,
      exam: round(sumBy(item, 'source_counts.exam')) || 0,
      mobile: round(sumBy(item, 'source_counts.mobile')) || 0,
      custom: round(sumBy(item, 'source_counts.custom')) || 0,
      sace: round(sumBy(item, 'source_counts.sace')) || 0,
    }));

    return merge(membership, trial, {
      show: {
        showSace,
      },
    });
  }, [data?.membership, data?.trial, typeView, showSace]);

  const option = useMemo(() => renderOption(transferDataView), [transferDataView]);

  const totalTrial = useMemo(() => sumBy(data?.trial, 'count') || 0, [data?.trial]);

  const totalTrialExam = useMemo(
    () => sumBy(data?.trial, 'source_counts.exam') || 0,
    [data?.trial],
  );

  const totalTrialMobile = useMemo(
    () => sumBy(data?.trial, 'source_counts.mobile') || 0,
    [data?.trial],
  );

  const totalTrialDomain = useMemo(
    () => sumBy(data?.trial, 'source_counts.custom') || 0,
    [data?.trial],
  );

  const totalSace = useMemo(() => sumBy(data?.trial, 'source_counts.sace') || 0, [data?.trial]);

  const totalAgent = useMemo(
    () => sumBy(data?.membership, 'codeTypeCount.agent') || 0,
    [data?.membership],
  );
  const totalRetail = useMemo(
    () => sumBy(data?.membership, 'codeTypeCount.retail') || 0,
    [data?.membership],
  );

  const totalRetailManual = useMemo(
    () => sumBy(data?.membership, 'codeTypeCount.retail_manual') || 0,
    [data?.membership],
  );

  const totalPartner = useMemo(
    () => sumBy(data?.membership, 'codeTypeCount.partner') || 0,
    [data?.membership],
  );

  const totalMembership = useMemo(
    () => totalAgent + totalRetail + totalPartner,
    [totalAgent, totalRetail, totalPartner],
  );

  const onChangeLegend = useCallback((params: { [key: string]: boolean }) => {
    setShowSace(!!params.Sace);
  }, []);

  const onClick = useCallback(
    (params: ECElementEvent) => {
      const newDate = time(params.name, 'DD-MM-YYYY').format('YYYY-MM-DD');
      void router.push({
        pathname: '/codes/active',
        query: {
          [QUERY.DATE_CODE]: newDate,
        },
      });
    },
    [router],
  );

  return (
    <ContainerPaper>
      <Typography.Title level={2}>Active Codes</Typography.Title>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <CopyTag valueCopy={totalTrial.toString()} color="success">
          Total trial:{' '}
          {showSace
            ? totalTrial.toLocaleString('vi')
            : (totalTrial - totalSace).toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalTrialExam.toString()}>
          Trial exam: {totalTrialExam.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalTrialMobile.toString()}>
          Trial mobile: {totalTrialMobile.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalTrialDomain.toString()}>
          Trial other: {totalTrialDomain.toLocaleString('vi')}
        </CopyTag>
        {showSace && (
          <CopyTag valueCopy={totalTrialDomain.toString()}>
            Trial sace: {totalSace.toLocaleString('vi')}
          </CopyTag>
        )}
        <CopyTag valueCopy={totalMembership.toString()} color="success">
          Total active: {totalMembership.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalAgent.toString()}>
          Code agent: {totalAgent.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalRetail.toString()}>
          Code retail: {totalRetail.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalRetailManual.toString()}>
          Code retail manual: {totalRetailManual.toLocaleString('vi')}
        </CopyTag>
        <CopyTag valueCopy={totalPartner.toString()}>
          Code partner: {totalPartner.toLocaleString('vi')}
        </CopyTag>
      </Box>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RangerDatePicker onChange={setDate} value={date} />
        <Radio.Group
          value={typeView}
          onChange={(e) => {
            setTypeView(e.target.value as number);
          }}
        >
          <Radio.Button value={CompletedTestView.DAY}>Day</Radio.Button>
          <Radio.Button value={CompletedTestView.WEEK}>Week</Radio.Button>
          <Radio.Button value={CompletedTestView.MONTH}>Month</Radio.Button>
        </Radio.Group>
      </Box>
      <ReactECharts
        option={option}
        style={{ width: '100%', height: '500px' }}
        // theme={'light'}
        loading={loadingTest}
        onChangeLegend={onChangeLegend}
        onClick={onClick}
      />
    </ContainerPaper>
  );
}
export default Membership;
