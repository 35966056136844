import ContainerPaper from '@/components/ContainerPaper';
import CopyTag from '@/components/copyTag';
import RangerDatePicker from '@/components/datePicker';
import { ReactECharts } from '@/components/echart';
import {
  useGetStudyTimeDay,
  useGetStudyTimeMonth,
  useGetStudyTimeWeek,
} from '@/graphql/generated/query';
import time from '@flyer/utils/time';
import { Box } from '@mui/material';
import { Radio, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { EChartsOption } from 'echarts';
import { fill, groupBy, keys, mapValues, orderBy, round, sumBy, toNumber, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const renderLineParam = (
  p: { seriesName: string; marker: string; data: string; dataIndex: number }[],
) => {
  return p
    .map((el) => {
      const dataItem = toNumber(el?.data)?.toLocaleString('vi')?.toString() || '0';
      return `<div style="display: flex; justify-content: space-between; width: 300px">
        <div>${el.marker} ${el.seriesName} </div>
        <div style="font-weight: normal">${dataItem} h</div>
        </div>`;
    })
    .join('');
};
const renderOption = (data: {
  [key: string]: {
    total_execution_time: number;
    total_exam_execution_time: number;
    total_mini_execution_time: number;
    total_speaking_execution_time: number;
    total_ket_pet_speaking_execution_time: number;
    total_ket_pet_writing_execution_time: number;
    total_speaking_ielts_execution_time: number;
    total_writing_execution_time: number;
    total_time_mobile: number;
    total_exam_execution_time_child: number;
  };
  legend: { [key: string]: string };
}): EChartsOption => {
  const legend = { ...data.legend };
  delete data.legend;
  const listKeyA = keys(data)
    .reverse()
    .map((el) =>
      time(el, 'YYYY-MM-DD', true).isValid() ? time(el, 'YYYY-MM-DD').format('DD-MM-YYYY') : el,
    );

  const listKey = orderBy(
    listKeyA,
    (date) => {
      const dateTime = time(date, 'DD-MM-YYYY');
      if (dateTime.isValid()) {
        return dateTime;
      }
      if (date.includes('W')) {
        return toNumber(date.replace('W', ''));
      }
      return date;
    },
    'asc',
  );

  const listValue = values(data).reverse();

  const totalExecutionTime = listValue.map((el) => el.total_execution_time);
  const totalExamExecutionTime = listValue.map((el) => el.total_exam_execution_time);
  const totalSpeakingExecutionTime = listValue.map((el) => el.total_speaking_execution_time);
  const totalMniExecutionTime = listValue.map((el) => el.total_mini_execution_time);

  const totalKetPetSpeaking = listValue.map((el) => el.total_ket_pet_speaking_execution_time);
  const totalKetPetWriting = listValue.map((el) => el.total_ket_pet_writing_execution_time);
  const totalIeltsSpeaking = listValue.map((el) => el.total_speaking_ielts_execution_time);
  const totalWriting = listValue.map((el) => el.total_writing_execution_time);
  const totalMobile = listValue.map((el) => el.total_time_mobile);
  const totalExamPractice = listValue.map((el) => el.total_exam_execution_time_child);

  const totalEmpty = fill([...listValue], 0);

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => {
        const newParams = params as unknown as [
          {
            seriesName: string;
            marker: string;
            data: string;
            dataIndex: number;
            axisValueLabel: string;
          },
        ];
        const timeDate = time(newParams?.[0]?.axisValueLabel, 'DD-MM-YYYY');
        let dateAxis = timeDate.isValid()
          ? timeDate.format('dddd - DD-MM-YYYY').toUpperCase()
          : newParams?.[0]?.axisValueLabel;

        if (dateAxis.length < 5 && dateAxis.includes('W')) {
          const weekNumber = toNumber(dateAxis.replace('W', ''));
          const startDate = time().week(weekNumber).startOf('week').format('DD/MM');
          const endDate = time().week(weekNumber).endOf('week').format('DD/MM');
          dateAxis = `${startDate} - ${endDate} - ${dateAxis}`;
        }

        const totalAllParam = newParams.find((el) => el.seriesName === 'Total Study Time');

        const totalAll = totalExecutionTime?.[totalAllParam?.dataIndex || 0] || '0';

        const paramFilterTotal = newParams.filter(
          (el) => !el.seriesName.includes('Total Study Time'),
        );

        return `     <div style="font-weight: bold; display: flex; justify-content: center; font-size: 16px">${dateAxis}</div>   
                <div style="font-weight: bold; display: flex; justify-content: space-between; font-size: 16px; margin-top: 4px">
            <div>Total Study Time </div>
            <div>${totalAll?.toLocaleString('vi')} h</div>
            </div>
          <div>${renderLineParam(paramFilterTotal)}</div>
        `;
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true, title: 'Total Study Time', name: 'total_execution_time' },
        dataZoom: { show: true },
      },
    },
    legend: {
      type: 'scroll',
      bottom: 0,
      width: '80%',
      data: [
        'Total Study Time',
        'Total Exam Study Time',
        'Total Speaking Study Time',
        'Total Mini Study Time',
        'Total Ket Pet Speaking',
        'Total Ket Pet Writing',
        'Total IELTS Speaking',
        'Total Writing',
        'Total Mobile',
        'Total Exam Practice',
      ],
      selected: {
        'Total Study Time': true,
        'Total Exam Study Time': true,
        'Total Speaking Study Time': true,
        'Total Mini Study Time': true,
        'Total Ket Pet Speaking': true,
        'Total Ket Pet Writing': true,
        'Total IELTS Speaking': true,
        'Total Writing': true,
        'Total Mobile': true,
        'Total Exam Practice': true,
        ...legend,
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        data: listKey,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Study Time',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: 'Total Exam Study Time',
        type: 'bar',
        yAxisIndex: 0,
        data: totalExamExecutionTime,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Speaking Study Time',
        type: 'bar',
        yAxisIndex: 0,
        data: totalSpeakingExecutionTime,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Mini Study Time',
        type: 'bar',
        yAxisIndex: 0,
        data: totalMniExecutionTime,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Ket Pet Speaking',
        type: 'bar',
        yAxisIndex: 0,
        data: totalKetPetSpeaking,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Ket Pet Writing',
        type: 'bar',
        yAxisIndex: 0,
        data: totalKetPetWriting,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total IELTS Speaking',
        type: 'bar',
        yAxisIndex: 0,
        data: totalIeltsSpeaking,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Writing',
        type: 'bar',
        yAxisIndex: 0,
        data: totalWriting,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Mobile',
        type: 'bar',
        yAxisIndex: 0,
        data: totalMobile,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Exam Practice',
        type: 'bar',
        yAxisIndex: 0,
        data: totalExamPractice,
        stack: 'a',
        label: {
          show: false,
          position: 'inside',
          formatter: (params) => params.data.toLocaleString('vi'),
        },
      },
      {
        name: 'Total Study Time',
        type: 'bar',
        yAxisIndex: 0,
        data: totalEmpty,
        stack: 'a',
        label: {
          show: true,
          position: 'top',
          formatter: (params) =>
            totalExecutionTime?.[params?.dataIndex]?.toLocaleString('vi') || '0',
        },
      },
    ],
  };
};

enum CompletedTestView {
  DAY,
  WEEK,
  MONTH,
}
function StudyTimeReport() {
  // const { themeMode } = useSettings();
  const [date, setDate] = useState<RangeValue>([time().subtract(2, 'week'), time()]);

  const [typeView, setTypeView] = useState(CompletedTestView.DAY);

  const [legend, setLegend] = useState({
    'Total Mini Study Time': true,
    'Total Study Time': true,
    'Total Exam Study Time': true,
    'Total Speaking Study Time': true,
  });

  const { data: dataByDay, loading: loadingTestDay } = useGetStudyTimeDay({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.DAY,
  });

  const { data: dataByWeek, loading: loadingTestWeek } = useGetStudyTimeWeek({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.WEEK,
  });

  const { data: dataByMonth, loading: loadingTestMonth } = useGetStudyTimeMonth({
    variables: {
      where: {
        date: {
          _gte: date?.[0]?.startOf('d')?.toISOString(),
          _lt: date?.[1]?.endOf('d')?.toISOString(),
        },
      },
    },
    skip: !date || typeView !== CompletedTestView.MONTH,
  });

  const transferDataView = useMemo(() => {
    let groupData = groupBy(
      dataByWeek?.admin_study_time_week,
      (item) => `W${time(item.date).week()}-${time(item.date).year()}`,
    );

    if (typeView === CompletedTestView.DAY) {
      groupData = groupBy(dataByDay?.admin_study_time_day, (item) =>
        time(item.date).format('YYYY-MM-DD'),
      );
    }

    if (typeView === CompletedTestView.MONTH) {
      groupData = groupBy(dataByMonth?.admin_study_time_month, (item) =>
        time(item.date).format('YYYY-MM'),
      );
    }

    const values = mapValues(groupData, (item) => ({
      total_execution_time: round(
        time.duration(sumBy(item, 'total_execution_time') || 0).asHours(),
      ),
      total_exam_execution_time: round(
        time.duration(sumBy(item, 'total_exam_execution_time') || 0).asHours(),
      ),
      total_mini_execution_time: round(
        time.duration(sumBy(item, 'total_mini_execution_time') || 0).asHours(),
      ),
      total_speaking_execution_time: round(
        time.duration(sumBy(item, 'total_speaking_execution_time') || 0).asHours(),
      ),
      total_ket_pet_speaking_execution_time: round(
        time.duration(sumBy(item, 'total_ket_pet_speaking_execution_time') || 0).asHours(),
      ),
      total_ket_pet_writing_execution_time: round(
        time.duration(sumBy(item, 'total_ket_pet_writing_execution_time') || 0).asHours(),
      ),
      total_speaking_ielts_execution_time: round(
        time.duration(sumBy(item, 'total_speaking_ielts_execution_time') || 0).asHours(),
      ),
      total_writing_execution_time: round(
        time.duration(sumBy(item, 'total_writing_execution_time') || 0).asHours(),
      ),
      total_time_mobile: round(time.duration(sumBy(item, 'total_time_mobile') || 0).asHours()),
      total_exam_execution_time_child: round(
        time.duration(sumBy(item, 'total_exam_execution_time_child') || 0).asHours(),
      ),
    }));
    values.legend = legend;
    return values;
  }, [
    dataByDay?.admin_study_time_day,
    dataByMonth?.admin_study_time_month,
    dataByWeek?.admin_study_time_week,
    typeView,
    legend,
  ]);

  const dataTest = useMemo(() => {
    let data = dataByWeek?.admin_study_time_week;
    if (typeView === CompletedTestView.DAY) {
      data = dataByDay?.admin_study_time_day;
    }

    if (typeView === CompletedTestView.MONTH) {
      data = dataByMonth?.admin_study_time_month;
    }
    return data;
  }, [
    dataByDay?.admin_study_time_day,
    dataByMonth?.admin_study_time_month,
    dataByWeek?.admin_study_time_week,
    typeView,
  ]);

  const totalStudytime = useMemo(
    () =>
      legend['Total Study Time']
        ? round(time.duration(sumBy(dataTest, 'total_execution_time') || 0).asHours()) || 0
        : 0,
    [dataTest, legend],
  );

  const totalExamStudyTime = useMemo(
    () =>
      legend['Total Exam Study Time']
        ? round(time.duration(sumBy(dataTest, 'total_exam_execution_time') || 0).asHours())
        : 0,
    [dataTest, legend],
  );
  const totalSpeakingStudyTime = useMemo(
    () =>
      legend['Total Speaking Study Time']
        ? round(time.duration(sumBy(dataTest, 'total_speaking_execution_time') || 0).asHours())
        : 0,
    [dataTest, legend],
  );

  const totalMiniStudyTime = useMemo(
    () =>
      legend['Total Mini Study Time']
        ? round(time.duration(sumBy(dataTest, 'total_mini_execution_time') || 0).asHours())
        : 0,
    [dataTest, legend],
  );

  const options = useMemo(() => renderOption(transferDataView), [transferDataView]);

  const onChangeLegend = useCallback((params: { [key: string]: boolean }) => {
    setLegend(params);
  }, []);

  return (
    <ContainerPaper>
      <Typography.Title level={2}>Study Time Report</Typography.Title>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <CopyTag valueCopy={totalStudytime.toString()} color="success">
          Total Study Time: {totalStudytime.toLocaleString('vi')} hours
        </CopyTag>
        <CopyTag valueCopy={totalExamStudyTime.toString()}>
          Total Total Exam Study Time: {totalExamStudyTime.toLocaleString('vi')} hours
        </CopyTag>
        <CopyTag valueCopy={totalSpeakingStudyTime.toString()}>
          Total Total Speaking Study Time: {totalSpeakingStudyTime.toLocaleString('vi')} hours
        </CopyTag>
        <CopyTag valueCopy={totalMiniStudyTime.toString()}>
          Total Total Mini Study Time: {totalMiniStudyTime.toLocaleString('vi')} hours
        </CopyTag>
      </Box>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RangerDatePicker onChange={setDate} value={date} />
        <Radio.Group
          value={typeView}
          onChange={(e) => {
            setTypeView(e.target.value as number);
          }}
        >
          <Radio.Button value={CompletedTestView.DAY}>Day</Radio.Button>
          <Radio.Button value={CompletedTestView.WEEK}>Week</Radio.Button>
          <Radio.Button value={CompletedTestView.MONTH}>Month</Radio.Button>
        </Radio.Group>
      </Box>

      <ReactECharts
        option={options}
        style={{ width: '100%', height: '500px' }}
        loading={loadingTestDay || loadingTestWeek || loadingTestMonth}
        onChangeLegend={onChangeLegend}
      />
    </ContainerPaper>
  );
}
export default StudyTimeReport;
