import { Button, DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import time from '@flyer/utils/time';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

function RangerDatePicker(props: { value: RangeValue; onChange: (timeProps: RangeValue) => void }) {
  const { value, onChange, ...rest } = props;

  const renderExtraFooter = useCallback(() => {
    return (
      <div>
        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().startOf('day'), time().endOf('day')]);
          }}
        >
          Today
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(7, 'day').startOf('day'), time().endOf('day')]);
          }}
        >
          7 days
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(14, 'day').startOf('day'), time().endOf('day')]);
          }}
        >
          14 days
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(30, 'day').startOf('day'), time().endOf('day')]);
          }}
        >
          30 days
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(90, 'day').startOf('day'), time().endOf('day')]);
          }}
        >
          90 days
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(6, 'month').startOf('day'), time().endOf('day')]);
          }}
        >
          6 mons
        </Button>

        <Button
          type="text"
          style={{
            color: 'green',
          }}
          onClick={() => {
            onChange([time().subtract(1, 'year').startOf('day'), time().endOf('day')]);
          }}
        >
          1 year
        </Button>
      </div>
    );
  }, [onChange]);

  return (
    <DatePicker.RangePicker
      onChange={onChange}
      value={value}
      format="DD-MM-YYYY"
      renderExtraFooter={renderExtraFooter}
      {...rest}
    />
  );
}
export default RangerDatePicker;
