import { useEffect, useMemo } from 'react';

declare global {
  interface Window {
    ZaloSocialSDK?: {
      reload: () => void;
    };
  }
}

export interface ZaloShareOptions {
  customize?: boolean;
  oaid?: string;
}

export interface ZaloShareAttributes {
  'data-customize'?: boolean;
  'data-oaid'?: string;
  'data-href': string;
}

export default function useZaloShare(
  link: string,
  options?: ZaloShareOptions,
): { zaloShareAttributes: ZaloShareAttributes; zaloShareClassName: string } {
  const { customize = true, oaid = '579745863508352884' } = options || {};

  useEffect(() => {
    let script = document.querySelector('#zaloScript') as HTMLScriptElement;
    if (script) {
      window.ZaloSocialSDK?.reload();
    } else {
      script = document.createElement('script');
      script.src = 'https://sp.zalo.me/plugins/sdk.js';
      script.id = 'zaloScript';
      document.body.append(script);
      const interval = window.setInterval(() => {
        if (window.ZaloSocialSDK) {
          window.clearInterval(interval);
          window.ZaloSocialSDK.reload();
        }
      }, 100);
    }
  }, []);

  const zaloShareAttributes = useMemo(() => {
    const payload: ZaloShareAttributes = {
      'data-customize': customize,
      'data-oaid': oaid,
      'data-href': link,
    };

    return payload;
  }, [customize, link, oaid]);

  return { zaloShareAttributes, zaloShareClassName: 'zalo-share-button' };
}
