import { useCallback } from 'react';

export default function useFacebookShare(
  initLink?: string,
  options?: { width: number; height: number },
) {
  const { width = 670, height = 500 } = options || {};

  const onShare = useCallback(
    (link?: string) => {
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - (height / 2 + 150);
      let sharedLink = initLink;
      if (typeof link === 'string') {
        sharedLink = link;
      }
      if (!sharedLink) {
        return;
      }

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          sharedLink,
        )}&display=popup`,
        'facebook-share-dialog',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
      );
    },
    [width, height, initLink],
  );

  return onShare;
}
