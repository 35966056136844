import type { ReactNode } from 'react';
import { Container, Paper } from '@mui/material';

export default function ContainerPaper({ children }: { children: ReactNode }) {
  return (
    <Container>
      <Paper elevation={8} sx={{ borderRadius: 2, p: 2 }}>
        {children}
      </Paper>
    </Container>
  );
}
